import Swiper, { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AOS from "aos";
import "aos/dist/aos.css";

export const Script = () => {
  // slider
  new Swiper(".product-swiper", {
    direction: "horizontal",
    loop: false,
    centeredSlides: true,
    navigation: {
      nextEl: ".product-swiper-button-next",
      prevEl: ".product-swiper-button-prev",
    },
    grabCursor: true,
    breakpoints: {
      0: {
        slidesPerView: 1.3,
        spaceBetween: 16,
      },
      576: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1400: {
        slidesPerView: 2.5,
        spaceBetween: 30,
      },
    },
  });

  new Swiper(".project-swiper", {
    modules: [Pagination],
    direction: "horizontal",
    loop: true,
    slidesPerView: 1,
    grabCursor: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  new Swiper(".recommendation-swiper", {
    modules: [Pagination],
    direction: "horizontal",
    loop: false,
    slidesPerView: 1,
    grabCursor: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  new Swiper(".project-glance-swiper", {
    modules: [Pagination],
    direction: "horizontal",
    loop: false,
    slidesPerView: 1,
    grabCursor: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  // AOS
  AOS.init({
    once: true,
  });
};

import { CustomTextField } from "./CustomTextField";
import "./texfield.css";

export function TextFields1({
  name,
  label,
  classNames,
  InputProps,
  type,
  value,
  onChange,
  sx,
}) {
  return (
    <CustomTextField
      value={value}
      sx={sx}
      onChange={onChange}
      className={`${classNames} field1`}
      name={name}
      label={label}
      InputProps={InputProps}
      type={type}
    />
  );
}

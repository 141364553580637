// Base for production
export const Base = `http://174.138.43.87:5001/`;

// export const Base = `http://localhost:5001/`;

const CURLPath = {
  json: {
    "Content-Type": "application/json",
  },
  form: {
    "Content-Type": "multipart/form-data",
  },
  register: "register",
  "admin/login": "admin/login",
  "admin/banner": "admin/banner",
  "admin/banner/isactive": "admin/banner/isactive",
  "admin/banner/update": "admin/banner/update",
  "admin/forgotpassword": "admin/forgotpassword",
  "admin/pages": "admin/pages",
  "admin/change_picture": "admin/change_picture",
  "admin/profile": "admin/profile",
  "admin/register": "admin/register",
  "user/changeProfilePic": "user/admin/getPage",
  "admin/getPage": "admin/getPage",
  "admin/addPage": "admin/addPage",
  "admin/page/update": "admin/page/update",
  "admin/page": "admin/page",
  updateUser: "updateUser",
  "admin/page/isactive": "admin/page/isactive",
  "admin/page/updateData": "admin/page/updateData",
  "admin/getHeaderMenu": "admin/getHeaderMenu",
};

export default CURLPath;

import "./header/Header.css";
import css from "!!raw-loader!./header/Header.css"; /* eslint import/no-webpack-loader-syntax: off */

export const myCustomBlock = {
  media: `<svg  viewBox="0 0 24 24">
    <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
</svg>`,
  id: "my-custom-block",
  label: "My Custom Block",
  content:
    '<div class="my-custom-block"> <h1>My Custom Blocksdfdsfs</h1>  <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example"><option selected>Open this select menu</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></div>',
  category: "Extra",
};

export const animatedDiv = {
  media: `<svg  viewBox="0 0 24 24">
    <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
</svg>`,
  id: "animatedDiv",
  label: "Animated Div",
  content: '<div data-aos="fade-up" data-aos-duration="1000">dfg</div>',
  category: "Animated_Section",
};

export const Heading = {
  media: `<svg  viewBox="0 0 24 24">
      <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
  </svg>`,
  id: "Heading",
  label: "Heading",
  content: "<h1>Heading</h1>",
  category: "Bootstrap",
};

export const Button = {
  media: `<svg  viewBox="0 0 24 24">
        <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
    </svg>`,
  id: "Button",
  label: "Button",
  content: <button class="btn btn-primary">Primary</button>,
  category: "Bootstrap",
};

export const Carousel = {
  media: `<svg  viewBox="0 0 24 24">
          <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
      </svg>`,
  id: "carousel",
  label: "carousel",
  content: (
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            src="https://images.pexels.com/photos/4406191/pexels-photo-4406191.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item">
          <img
            src="https://images.pexels.com/photos/11953139/pexels-photo-11953139.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item">
          <img
            src="https://images.pexels.com/photos/15060067/pexels-photo-15060067.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load"
            class="d-block w-100"
            alt="..."
          />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden"></span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden"></span>
      </button>
    </div>
  ),
  category: "Bootstrap",
};

export const Navbar = {
  media: `<svg  viewBox="0 0 24 24">
            <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
        </svg>`,
  id: "navbar",
  label: "navbar",

  content: [
    {
      styles: css,
    },

    `<header>
      <div class="logo"></div>
      <div class="hamburger">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <nav class="nav-bar">
        <ul class="mt-3">
          <li  onClick={myFunction}>
            <a href="sdfdsf" class="active">
              Home
            </a>
          </li>
          <li>
            <a href="sdfdsf">Features</a>
          </li>
          <li>
            <a href="sdfdsf">Pricing</a>
          </li>
          <li>
            <a href="sdfdsf">About</a>
          </li>
          <li>
            <a href="sdfdsf">Contact</a>
          </li>
        </ul>
      </nav>
    </header>
    <script>
    function myFunction() {
      alert("hy")
      }
      </script>`,
  ],

  category: "Bootstrap",
};

// import { WiSolarEclipse } from "react-icons/wi";
// @mui
import { Stack } from "@mui/system";
import { styled } from "@mui/material/styles";
import { Container, Typography, Box } from "@mui/material";
// import { motion } from "framer-motion";
// hooks
import useResponsive from "../../../hooks/useResponsive";
import { Link1 } from "../../../components/links/CustomLink";
import { TextFields1 } from "../../../components/textFields/TextFields";
import { BigButton } from "../../../components/buttons/Buttons";
import HeadMananger from "../../../components/headManager/HeadMananger";
import { useState } from "react";
import Validator from "validatorjs";
import { Error, Success, Validate } from "../../../constant/Util";
import { POST } from "../../../constant/RequestAuthService";

import login_img from "../../../images/login_img.jpg";
import footer_logo from "../../../images/footer_logo.png";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  const mdUp = useResponsive("up", "md");
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleClick = async () => {
    const checkdata = {
      email: "required|email",
    };

    setDisabled(true);
    const sendData = { email };
    const validation = new Validator(sendData, checkdata);

    if (validation.fails()) {
      Validate(validation);
    } else {
      const response = await POST("admin/forgotpassword", sendData);
      if (response.status === 200) {
        // navigate("/dashboard/app");
        Success(response.message);
        // dispatch({ type: userLogin, payload: response.data });
        setDisabled(false);
      } else {
        Error(response.message);
        setDisabled(false);
      }
    }
    setDisabled(false);
  };

  return (
    <>
      <HeadMananger title="Forgot Password " />

      <StyledRoot>
        <Box
          sx={{
            position: "fixed",
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        >
          <img
            src={footer_logo}
            alt=""
            width={"180px"}
            style={{ padding: "0 ", marginBottom: "20px" }}
          />
          {/* <WiSolarEclipse
            style={{
              fontSize: "30px",
              color: "#006666",
              cursor: "pointer",
            }}
          /> */}
        </Box>

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 3, mb: 3 }}>
              Hi, Welcome
            </Typography>
            {/* <motion.img className="login_image" src={login} alt="login" /> */}

            <div style={{ padding: "0 40px" }}>
              <img
                src={login_img}
                alt=""
                style={{
                  width: "100%",
                  height: "300px",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </div>
          </StyledSection>
        )}
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Forgot Password
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Remember password? {""}
              {/* <Link to="/login" variant="subtitle2" className="secondary_main">
                Login now
              </Link> */}
              <Link1 to="/login" title=" Login now" />
            </Typography>
            <Stack spacing={3} sx={{ my: 2 }}>
              {" "}
              <TextFields1
                name="email"
                label="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Stack>
            <BigButton
              title={"Submit"}
              onClick={handleClick}
              disabled={disabled}
            />
            {/* <LoginForm /> */}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

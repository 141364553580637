import { Helmet } from "react-helmet-async";
// import { WiSolarEclipse } from "react-icons/wi";
// @mui
import { styled } from "@mui/material/styles";
import { Container, Typography, Box } from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";

// components

// sections
import LoginForm from "./LoginForm";
import login_img from "../../../images/login_img.jpg";
import footer_logo from "../../../images/footer_logo.png";
import { Link1 } from "../../../components/links/CustomLink";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive("up", "md");

  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      <StyledRoot>
        <Box
          sx={{
            position: "fixed",
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        >
          <img
                src={footer_logo}
                alt=""
                width={"180px"}
                style={{ padding: "0 ", marginBottom:"20px" }}
              />
          {/* <WiSolarEclipse
            style={{
              fontSize: "30px",
              color: "#006666",
              cursor: "pointer",
            }}
          /> */}
        </Box>
        
        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 3, mb: 3 }}>
              Hi, Welcome
            </Typography>
            {/* <motion.img className="login_image" src={login} alt="login" /> */}
            
            <div style={{ padding: "0 40px" }}>
              <img
                src={login_img}
                alt=""
                style={{
                  width: "100%",
                  height: "300px",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
              />
            </div>
          </StyledSection>
        )}
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {""}
              {/* <Link to="/register" variant="subtitle2" style={{ color: `var(--700)`, fontWeight: 700 }}>
                Get started
              </Link> */}
              <Link1 to="/register" title="Get Started" />
            </Typography>

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

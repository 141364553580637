// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/scroll-to-top";
import { StyledChart } from "./components/chart";
import "./theme/css/Global.css";
import "./pages/Page.css";
import { QueryClient, QueryClientProvider } from "react-query";
import "./pages/editor/customBlocks/header/Header.css";

// ----------------------------------------------------------------------

export default function App() {
  if (
    window.location.hostname !== "localhost" &&
    window.location.protocol === "https:"
  ) {
    window.location = "http://" + window.location.hostname;
  }

  const queryClient = new QueryClient();
  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

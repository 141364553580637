import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  // Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
} from "@mui/material";
import profile from "../../../images/profile.webp";
// mocks_

import { useLogoutFunctanilty } from "../../../redux_service/apiService/logout/useLogout";
import { UserProfile } from "../../../constant/Util";
import { imgUrl } from "../../../constant";

// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   {
//     label: "Home",
//     icon: "eva:home-fill",
//   },
//   {
//     label: "Profile",
//     icon: "eva:person-fill",
//   },
//   {
//     label: "Settings",
//     icon: "eva:settings-2-fill",
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const { LogoutUser } = useLogoutFunctanilty();
  const [errorImage, setErrorImage] = useState(false);

  const UserData = UserProfile();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  // console.log(UserData);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          src={errorImage ? profile : `   ${imgUrl}${UserData.picture}`}
          alt="photoURL"
          onError={() => setErrorImage(true)}
        />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {UserData?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {UserData?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={() => LogoutUser()} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./Demo.scss";

const Demo = () => {
  const Data = useSelector((state) => state.auth.demoData);
  const [css, setCss] = useState(null);
  const [js, setJs] = useState(null);

  useEffect(() => {
    GetCss();
    GetJs();
  }, [Data]); // eslint-disable-line react-hooks/exhaustive-deps

  //Css

  const GetCss = () => {
    const cssString = Data?.Css;
    const style = document.createElement("style");
    style.appendChild(document.createTextNode(cssString));
    document.head.appendChild(style);

    setCss({ cssString });
  };

  //Js

  const GetJs = () => {
    const JsString = Data?.Js;
    const script = document.createElement("script");
    script.appendChild(document.createTextNode(JsString));
    document.head.appendChild(script);

    setJs({ JsString });
  };

  // console.log(Jstyles);

  return (
    <>
      <div
        script={js}
        style={css}
        dangerouslySetInnerHTML={{ __html: Data?.html }}
      ></div>
    </>
  );
};

export default Demo;

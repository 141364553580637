import React from "react";
import "./footer.css";
import { staticArr } from "../../home/Home";
import footerLogo from "../../../images/footer_logo.png";
import { Link as RouterLink } from "react-router-dom";

const products = [
  { name: "Photovoltaic Floater", link: "/productOne" },
  { name: "Walkway Floater", link: "/productTwo" },
  { name: "Inverter Floater", link: "/productThree" },
  { name: "Mooring Cum Floater", link: "/productFour" },
];

const Footer = () => {
  return (
    <>
      <footer id="Contact" className="my-footer">
        <div className="footer-background">
          <div className="container stay-touch">
            <h1 className="m-0">Stay in Touch</h1>
            <div className="col-12 col-sm-8 col-lg-6">
              <form action="" className="d-flex flex-column flex-sm-row gap-4">
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email Address"
                  className="stay-email-input flex-grow-1"
                />
                <input
                  type="submit"
                  value="Submit"
                  className="stay-email-submit"
                />
              </form>
            </div>
          </div>
          <div className="container footer-sec pt-5 pb-3 my-5">
            <div className="row gx-md-5">
              <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                <ul>
                  <li className="mb-4">GIVE US A RING</li>
                  <li>
                    <a href="mailto:connect@quantsolar.com">
                      connect@quantsolar.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:+919903857365">+91-99038-57365</a>
                  </li>
                  <li>
                    <a href="tel:+918294708195">+91-82947-08195</a>
                  </li>
                  <li className="addr">
                    Technology Incubation Center IIT Guhawati, INDIA
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-lg-2 mb-4 mb-lg-0">
                <ul>
                  <li className="mb-4">ABOUT</li>
                  {staticArr?.length &&
                    staticArr.map((data, i) => (
                      <li className="pointer" key={i}>
                        <RouterLink
                          to={`/${data.name[0].toLowerCase()}${data.name.slice(
                            1
                          )}`}
                        >
                          {data.name}
                        </RouterLink>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="col-md-6 col-lg-2 mb-4 mb-lg-0">
                <ul>
                  <li className="mb-4">PRODUCTS</li>
                  {products.map((data, i) => (
                    <li className="pointer" key={i}>
                      <RouterLink to={data.link}>{data.name}</RouterLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-6 col-lg-2 mb-4 mb-lg-0">
                <ul>
                  <li>
                    <RouterLink to="/blogListPage">BLOG</RouterLink>
                  </li>
                  <li>
                    <a href="/">FAQ</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-lg-2 mb-4 mb-lg-0">
                <div className="social d-flex align-items-center gap-4">
                  <i className="fs_2 fa-brands fa-facebook"></i>
                  <i className="fs_2 fa-brands fa-twitter"></i>
                  <i className="fs_2 fa-brands fa-linkedin-in"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="container pb-5">
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-3">
              <img src={footerLogo} alt="" srcset="" width="200px" />
              <p className="m-0">
                Copyright {new Date().getFullYear()}, All rights reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

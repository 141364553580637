import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../components/navbar/Navbar";
import { GET } from "../../constant/RequestAuthService";
import { Script } from "../../script/test.js";
import Footer from "../components/footer/Footer";
import loadingss from "../../images/loader.gif";
import SideBar from "../components/sideBar/SideBar";

export const staticArr = [
  { name: "Home", link: true },
  { name: "About", link: true },
  { name: "Products", link: true },
  { name: "Services", link: true },
  { name: "Projects", link: true },
  { name: "Contact", link: true },
];

const Home = () => {
  const [data, setData] = useState(null);
  const [css, setCss] = useState(null);
  const [js, setJs] = useState(null);
  const [customjs, setCustomJs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openSideBar, setOpenSideBar] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    !id && navigate("/home");
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (id) {
        const response = await GET("admin/page", { id });
        if (response.status === 200) {
          const { data } = response;
          setData(data);
          setLoading(false);
        }
      }
      setLoading(false);
    };

    getData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    GetCss();
    GetJs();
    GetCustomJs();
    Script();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  //Css

  const GetCss = () => {
    const cssString = data?.Css;
    const style = document.createElement("style");
    style.appendChild(document.createTextNode(cssString));
    document.head.appendChild(style);

    setCss({ cssString });
  };

  const GetJs = () => {
    const JsString = data?.Js;
    const script = document.createElement("script");
    script.appendChild(document.createTextNode(JsString));
    document.head.appendChild(script);

    setJs({ JsString });
  };

  const GetCustomJs = () => {
    const JsString = Script;
    const script = document.createElement("script");
    script.appendChild(document.createTextNode(JsString));
    document.head.appendChild(script);

    setCustomJs({ JsString });
  };

  //To Change inner Html (vip)
  // useEffect(() => {
  //   const heading = document.querySelector(".heading");
  //   if (heading) {
  //     heading.innerHTML = "New heading";
  //   }
  // }, [data?.Html]);

  // useEffect(() => {
  //   if (data !== null && data.name === "Home") {
  //     document
  //       .querySelector(".banner-yello-btn")
  //       .addEventListener("click", () => {
  //         // setOpenSideBar(true);
  //         console.log("ok");
  //       });
  //     console.log(document.querySelector(".banner-yello-btn"));
  //   }
  // }, []);

  const addEvent = () => {
    const btn = document.querySelector(".banner-yello-btn");
    if (btn) {
      document
        .querySelector(".banner-yello-btn")
        .addEventListener("click", () => {
          setOpenSideBar(true);
        });
    }
  };

  return (
    <div style={{ minHeight: "100vh" }} className="d-flex flex-column">
      {loading === true ? (
        <div
          style={{ minHeight: "100vh" }}
          className="d-flex align-items-center justify-content-center flex-column"
        >
          <img src={loadingss} alt="loading" width="30px" />
          <h4 className="mt-4">Loading app .</h4>
        </div>
      ) : (
        <>
          {data !== null && <Header data={data} />}
          <div
            script={(js, customjs)}
            style={css}
            dangerouslySetInnerHTML={{ __html: data?.Html }}
            data-aos="fade"
          ></div>
          {data !== null && (
            <div className="mt-auto">
              <Footer />
            </div>
          )}
          {data !== null && data.name === "Home" && (
            <>
              <SideBar
                openSideBar={openSideBar}
                setOpenSideBar={setOpenSideBar}
              />
              {addEvent()}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Home;

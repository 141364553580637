import { Navigate, useRoutes } from "react-router-dom";
import { lazy } from "react";

// layouts
import { useSelector } from "react-redux";
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import LoginPage from "./pages/auth/login/LoginPage";
import Page404 from "./pages/Page404";
import ProductsPage from "./pages/ProductsPage";
import RegisterPage from "./pages/auth/register/RegisterPage";
import ForgotPassword from "./pages/auth/forgotPassword/ForgotPassword";
import { Suspense } from "react";
import Loader from "./components/loader/Loader";
import Demo from "./pages/copyright/Demo";
import Home from "./user_View/home/Home";
import Page from "./pages/pages/Page";

const LazyBlog = lazy(() => import("./pages/BlogPage"));
const LazyBanner = lazy(() => import("./pages/banner/Banner"));
const LazyProfile = lazy(() => import("./pages/profile/ProfilePage"));
const LazyCopyRight = lazy(() => import("./pages/copyright/CopyRight"));
const LazyProject = lazy(() => import("./pages/project/Projects"));
const LazyEditor = lazy(() => import("./pages/editor/WordEditor"));
const LazyPages = lazy(() => import("./pages/pages/Page"));

// ----------------------------------------------------------------------

export default function Router() {
  const user = useSelector((state) => state.auth.userProfile);

  const SuspenseCreator = ({ Component }) => {
    return user === null ? (
      <Navigate to="/login" />
    ) : (
      <Suspense fallback={<Loader open />}>{Component}</Suspense>
    );
  };

  const routes = useRoutes([
    {
      path: "/admin",
      element: <SuspenseCreator Component={<DashboardLayout />} />,

      children: [
        { element: <Navigate to="/admin/app" />, index: true },
        { path: "app", element: <Page /> },
        {
          path: "user",
          element: <SuspenseCreator Component={<LazyBanner />} />,
        },
        { path: "products", element: <ProductsPage /> },
        {
          path: "copyright",
          element: <SuspenseCreator Component={<LazyCopyRight />} />,
        },

        {
          path: "project",
          element: <SuspenseCreator Component={<LazyProject />} />,
        },
        {
          path: "blog",
          element: <SuspenseCreator Component={<LazyBlog />} />,
        },
        {
          path: "profile",
          element: <SuspenseCreator Component={<LazyProfile />} />,
        },
        {
          path: "editor",
          element: <SuspenseCreator Component={<LazyEditor />} />,
        },
        {
          path: "page",
          element: <SuspenseCreator Component={<LazyPages />} />,
        },
      ],
    },
    {
      path: "login",
      element: user !== null ? <Navigate to="/admin" /> : <LoginPage />,
    },
    {
      path: "forgotPassword",
      element: user !== null ? <Navigate to="/admin" /> : <ForgotPassword />,
    },
    {
      path: "demo",
      element: <SuspenseCreator Component={<Demo />} />,
    },
    {
      path: "register",
      element: <RegisterPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        // { element: <Navigate to="/page/home" />, index: true },
        // { element: <Page /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
    {
      path: ":id",
      element: <Home />,
    },
    {
      path: "",
      element: <Home />,
    },
  ]);

  return routes;
  //  </Suspense>
}

import { useDispatch } from 'react-redux';
import { logoutUser } from '../../authSlice';

export const useLogoutFunctanilty = () => {
  const dispatch = useDispatch();
  const LogoutUser = async () => {
    dispatch({ type: logoutUser });
  };
  return { LogoutUser };
};

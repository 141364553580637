import { useState } from "react";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  // Checkbox,
  // FormControlLabel,
} from "@mui/material";
import Validator from "validatorjs";
import { useDispatch } from "react-redux";
// components
import { useNavigate } from "react-router-dom";
import { TextFields1 } from "../../../components/textFields/TextFields";
import Iconify from "../../../components/iconify";
import { BigButton } from "../../../components/buttons/Buttons";
import { Error, Success, Validate } from "../../../constant/Util";
import { POST } from "../../../constant/RequestAuthService";
import { userLogin } from "../../../redux_service/authSlice";
import { Link2 } from "../../../components/links/CustomLink";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = async () => {
    const checkdata = {
      email: "required|email",
      password: "required",
    };

    setDisabled(true);
    const sendData = { email, password };
    const validation = new Validator(sendData, checkdata);

    if (validation.fails()) {
      Validate(validation);
    } else {
      const response = await POST("admin/login", sendData);
      if (response.status === 200) {
        navigate("/admin/app");
        Success(response.message);
        dispatch({ type: userLogin, payload: response.data });
        setDisabled(false);
      } else {
        Error(response.message);
        setDisabled(false);
      }
    }
    setDisabled(false);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextFields1
          name="email"
          label="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextFields1
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        // justifyContent="space-between"
        justifyContent="flex-end"
        sx={{ my: 2 }}
      >
        {/* <Checkbox name="remember" label="Remember me" /> */}
        {/* <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: "var(--601)",
                "&.Mui-checked": {
                  color: "var(--601)",
                },
              }}
            />
          }
          label="Remember Me"
        /> */}

        <Link2 to="/forgotPassword" title="Forgot Password" />
      </Stack>

      <BigButton title={"Login"} onClick={handleClick} disabled={disabled} />
    </>
  );
}

import React, { useState, useEffect } from "react";
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";

import { NormalButton } from "../../components/buttons/Buttons";
// import { useDispatch } from "react-redux";
// import { addDemo } from "../../redux_service/authSlice";
// import { Success } from "../../constant/Util";
import { Typography } from "@mui/material";
import { GET, POST } from "../../constant/RequestAuthService";
import { Success, Error } from "../../constant/Util";
import { Script } from "../../script/test.js";

import "grapesjs-plugin-toolbox/dist/grapesjs-plugin-toolbox.min.css";
import {
  blockManager,
  canvas,
  plugins,
  pluginsOpts,
} from "./EditorCustomisation";
import { Header } from "./customBlocks/header/Header";

const WordEditor = ({ pageInfo, openEditor }) => {
  const [editor, setEditor] = useState(null);
  const [getData, setGetData] = useState(false);
  const [Html, setHtml] = useState("");
  const [Css, setCss] = useState("");
  const [Js, setJs] = useState("");

  // console.log(pageInfo);

  useEffect(() => {
    const getData = async () => {
      Script();
      const response = await GET("admin/page", { id: pageInfo.url });
      if (response.status === 200) {
        const { data } = response;
        // console.log(data.Html);

        setHtml(data.Html);
        setCss(data.Css);
        setJs(data.Js);
        setGetData(false);
        setTimeout(() => {
          setGetData(true);
        }, 300);

        //TO CHNAGE CONTENT DYNAMICALLY (vip)

        // const parser = new DOMParser();
        // const htmlDoc = parser.parseFromString(data?.Html, "text/html");
        // // console.log(htmlDoc);

        // // Get the h1 element with the class 'heading'
        // const heading = htmlDoc.querySelector(".heading");

        // // Update the text content of the h1 element

        // heading.innerHtml = "<h1 class=`heading`>New heading text</h1>";
        // // heading.textContent = "New heading text";

        // // heading.innerHTML = "<h5>New heading text</h5>";

        // // Get the updated HTML string
        // const updatedHtml = htmlDoc.documentElement.outerHTML;

        // setHtml(updatedHtml);
      }
    };

    getData();
  }, [pageInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(Html);

  useEffect(() => {
    const Editor = async () => {
      // const am = editor.AssetManager;

      const editor = grapesjs.init({
        container: "#editor",

        blockManager: blockManager,

        //Using Custom Storage
        storageManager: false,
        //

        //For js
        allowScripts: 1,
        //

        //Plugins used
        plugins: plugins,
        pluginsOpts: pluginsOpts,
        canvas: canvas,
        onLoaded: function () {
          console.log(editor.getConfig());
          // modify the default font family in the editor configuration
          editor.getConfig().defaultStyle.fontFamily = "Impact";
        },
      });

      //Header
      Header(editor);

      //Get Previous html by Get Api
      await editor.setComponents(Html);
      await editor.setStyle(Css);
      await editor.setCustomRte(Js);
      //

      setEditor(editor);

      // console.log(editor.getConfig().defaultStyle);

      // editor.addComponents(`<input name="my-test" title="hello"/>`);
    };
    Editor();
  }, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

  const AddData = async () => {
    const requestData = {
      Html: editor.getHtml(),
      Css: editor.getCss(),
      Js: editor.getJs(),
    };
    // console.log(requestData.Html);
    const response = await POST("admin/page/updateData", {
      requestData,
      id: pageInfo._id,
    });
    if (response.status === 200) {
      Success(response.message);
    } else {
      Error(response.message);
      console.log(response.message);
    }
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {pageInfo.name}
      </Typography>
      <div style={{ listStyleType: "none" }} id="editor"></div>
      <div className="d-flex justify-content-between my-4">
        <NormalButton title="Back" onClick={() => openEditor(false)} />
        <NormalButton title="Update" onClick={AddData} />
      </div>
    </>
  );
};

export default WordEditor;

import React from "react";
import "./sideBar.css";

const SideBar = ({ openSideBar, setOpenSideBar }) => {
  return (
    <div className={openSideBar ? "my-side-bar active" : "my-side-bar"}>
      <section id="contact-form">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h2 className="heading-blue-2 fw-semibold fs_24 m-0">Get In Touch</h2>
          <i
            style={{ color: "#142454" }}
            className="fa-solid fa-xmark pointer"
            onClick={() => {
              setOpenSideBar(false);
            }}
          ></i>
        </div>
        <form method="post" action="path/to/contact-form-handler.php">
          <div className="form-group">
            <label htmlFor="name" className="mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required
              className="form-control mb-3"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email" className="mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="form-control mb-3"
            />
          </div>
          <div className="form-group">
            <label htmlFor="message" className="mb-2">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              rows="6"
              required
              className="form-control mb-3"
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary mt-3">
            Send Message
          </button>
        </form>
      </section>
    </div>
  );
};

export default SideBar;

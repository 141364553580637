import { Typography } from "@mui/material";
import React from "react";
import { NormalButton } from "../../components/buttons/Buttons";
import { POST } from "../../constant/RequestAuthService";
import { Success, Error } from "../../constant/Util";

const ActivePageModal = ({
  open,
  setOpen,
  refetch,
  id,
  setId,
  URL,
  Status,
  setStatus,
}) => {
  const setModalOperation = async (confirm) => {
    if (confirm === true) {
      const response = await POST(URL, { id: id });
      // console.log(response);
      if (response.status === 200) {
        Success(response.message);
        refetch();
        await setId(0);
        await setStatus("");
        await setOpen(!open);
      } else {
        Error(response.message);
      }
    }
    await setOpen(!open);
  };
  return (
    <>
      {" "}
      <Typography variant="h4" gutterBottom align="center">
        Confirmation
      </Typography>
      <Typography variant="h6" gutterBottom align="center">
        Are you sure you want to Inactivate ?
      </Typography>
      <div
        style={{ gap: "15px" }}
        className="mt-4 d-flex align-items-center justify-content-center"
      >
        <NormalButton
          onClick={() => setModalOperation(true)}
          title={Status === 0 || Status === "0" ? "Inactivate" : "Activate"}
        />
        <NormalButton negative title="Cancel" onClick={() => setOpen(!open)} />
      </div>
    </>
  );
};

export default ActivePageModal;

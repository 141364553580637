// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  // {
  //   title: "dashboard",
  //   path: "/admin/app",
  //   icon: icon("dashboard"),
  // },
  // {
  //   title: "Banner",
  //   path: "/admin/user",
  //   icon: icon("banner"),
  // },
  // {
  //   title: "Projects",
  //   path: "/admin/project",
  //   icon: icon("project"),
  // },
  {
    title: "Pages",
    path: "/admin/page",
    icon: icon("page.png"),
  },
  // {
  //   title: "Demo",
  //   path: "/demo",
  //   icon: icon("project"),
  // },
  // {
  //   title: "Copyright",
  //   path: "/admin/copyright",
  //   icon: icon("copyright"),
  // },
  // {
  //   title: "Editor",
  //   path: "/admin/editor",
  //   icon: icon("copyright"),
  // },
  // {
  //   title: "product",
  //   path: "/admin/products",
  //   icon: icon("ic_cart"),
  // },
  // {
  //   title: "blog",
  //   path: "/admin/blog",
  //   icon: icon("ic_blog"),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  {
    title: "profile",
    path: "/admin/profile",
    icon: icon("profile.png"),
  },
  {
    title: "Not found",
    path: "/404",
    icon: icon("ic_disabled.svg"),
  },
];

export default navConfig;

import css from "!!raw-loader!./Header.css"; /* eslint import/no-webpack-loader-syntax: off */

// const menu = ["home", "about", "contact"];

export const Header = (editor) => {
  editor.Components.addType("comp-with-js", {
    model: {
      defaults: {
        script,

        content: `<header>
        <div class="logo">
            LOGO
        </div>
        <div class="hamburger">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
        <nav class="nav-bar">
            <ul>

            <li><a href="" class="active">Home</a></li>
            <li><a href="" >Features</a></li>
            <li><a href="" >Pricing</a></li>
            <li><a href="" >About</a></li>
            <li><a href="" >Contact</a></li>
               
               
            </ul>
        </nav>
    </header>`,
        style: css,
      },
    },
  });

  editor.Blocks.add("test-block", {
    label: "Test block",
    attributes: { class: "fa fa-text" },
    content: [
      {
        styles: css,
      },
      {
        type: "comp-with-js",
      },
    ],
    category: "Extra",
  });
};

const script = function () {
  const hamburger = document.querySelector(".hamburger");

  const navBar = document.querySelector(".nav-bar");

  hamburger.addEventListener("click", () => {
    navBar.classList.toggle("active");
  });
};

//   ${menu
//     .map(
//       (item) => `
//   <li>
//     <a href="${item}" class="${item === "home" ? "active" : ""}">
//       ${item.charAt(0).toUpperCase() + item.slice(1)}
//     </a>
//   </li>
// `
//     )
//     .join("")}

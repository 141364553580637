import { CircularProgress } from '@mui/material';
import React from 'react';

const Loader = ({ open }) => {
  return (
    <div>
      <div style={{ minHeight: '70vh', opacity: 0.7 }} className="d-flex align-items-center justify-content-center">
        <CircularProgress color="secondary" />
      </div>
    </div>
  );
};

export default Loader;

import { Typography } from "@mui/material";
import React from "react";
import { NormalButton } from "../../components/buttons/Buttons";
import { DELETE } from "../../constant/RequestAuthService";
import { Success, Error } from "../../constant/Util";

const DeletePage = ({ open, setOpen, refetch, id, setId, URL }) => {
  const setModalOperation = async (confirm) => {
    if (confirm === true) {
      const response = await DELETE(URL, { id: id });
      // console.log(response);
      if (response.status === 200) {
        Success(response.message);
        await setId(0);
        setOpen(false);
        refetch();
      } else {
        Error(response.message);
      }
    }
    await setOpen(!open);
  };
  return (
    <>
      {" "}
      <Typography variant="h4" gutterBottom align="center">
        Confirmation
      </Typography>
      <Typography variant="h6" gutterBottom align="center">
        Are you sure you want to Delete ?
      </Typography>
      <div
        style={{ gap: "15px" }}
        className="mt-4 d-flex align-items-center justify-content-center"
      >
        <NormalButton title="Submit" onClick={() => setModalOperation(true)} />
        <NormalButton negative title="Cancel" onClick={() => setOpen(!open)} />
      </div>
    </>
  );
};

export default DeletePage;

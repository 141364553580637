import { Typography } from "@mui/material";
import React, { useState } from "react";
import Validator from "validatorjs";
import { NormalButton } from "../../components/buttons/Buttons";
import { TextFields1 } from "../../components/textFields/TextFields";
import { POST } from "../../constant/RequestAuthService";
import { Success, Validate, Error } from "../../constant/Util";

const AddPage = ({ open, setOpen, refetch }) => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [slug, setSlug] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      name: name.toLowerCase().charAt(0).toUpperCase() + name.slice(1),
      url,
      slug,
    };

    const validation = new Validator(requestData, {
      name: "required",
      url: "required|alpha",
    });
    if (validation.fails()) {
      await Validate(validation);
    } else {
      const response = await POST("admin/addPage", requestData);
      if (response.status === 200) {
        Success(response.message);
        setOpen(false);

        refetch();
      } else {
        Error(response.message);
      }
    }
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Add Page
      </Typography>
      <TextFields1
        label="Page Name"
        classNames="w-100 mt-2"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextFields1
        label="Url"
        classNames="w-100 mt-2"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <Typography fontSize={13}>eg: contact || home || about</Typography>
      <TextFields1
        label="Slug "
        classNames="w-100 mt-2"
        value={slug}
        onChange={(e) => setSlug(e.target.value)}
      />
      <div style={{ gap: "15px" }} className="mt-4 d-flex align-items-center">
        <NormalButton title="Submit" onClick={(e) => handleSubmit(e)} />
        <NormalButton negative title="Cancel" onClick={() => setOpen(!open)} />
      </div>
    </>
  );
};

export default AddPage;

import { styled } from "@mui/material/styles";
import { FormControl, TextField } from "@mui/material";

export const CustomTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "var(--600)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "var(--600)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "var(--600)",
    },
    "&:hover fieldset": {
      borderColor: `var(--600)`,
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--600)",
    },
  },
});

export const CustomSelect = styled(FormControl)({
  "& label.Mui-focused": {
    color: "var(--600)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "var(--600)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "var(--600)",
    },
    "&:hover fieldset": {
      borderColor: `var(--600)`,
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--600)",
    },
  },
});

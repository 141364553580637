import { IconButton, Switch, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React from "react";
import { NormalButton } from "../../components/buttons/Buttons";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../../components/modals/CustomModal";
import { useState } from "react";
import AddPage from "./AddPage";
import { Helmet } from "react-helmet-async";
import useWindowDimensions from "../../components/widthFinder/WidthFinder";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Datatable from "../../components/dataTable/Datatable";
import { useQuery } from "react-query";
import { GET } from "../../constant/RequestAuthService";
import EditPage from "./EditPage";
import DeletePage from "./DeletePage";
import ActivePageModal from "./ActivePageModal";
import WordEditor from "../editor/WordEditor";
// import MoreVertIcon from "@mui/icons-material/MoreVert";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Page = () => {
  const [modal, setModal] = useState(false);
  const { width } = useWindowDimensions();
  const final_width = parseInt(width / 5);
  const [id, setId] = useState(0);
  const [PageData, setPageData] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [Status, setStatus] = useState(0);
  const [activeModal, setActiveModal] = useState(false);
  const [editor, setEditor] = useState(false);
  const [pageInfo, setPageInfo] = useState(null);

  const {
    data: pageData = [],
    isLoading,
    refetch,
  } = useQuery("getPage", async () => await GET("admin/getPage"));

  const handleEdit = async (params) => {
    await setId(params.row._id);
    await setPageData(params.row);
    await setEditModal(!editModal);
  };

  const handleDelete = async (params) => {
    // consloe.log(params.row.id);
    await setId(params.row._id);
    await setDeleteModal(!deleteModal);
  };

  const handleActive = async (params) => {
    await setId(params.row._id);
    await setStatus(params.row.is_active);
    await setActiveModal(!activeModal);
  };

  // console.log(order);

  const openEditor = (data) => {
    setEditor(true);
    setPageInfo(data);
  };

  const columns = [
    {
      field: "Name",
      headerName: "Name",
      width: final_width,
      renderCell: (params) => params.row.name,
    },
    {
      field: "action",
      headerName: "Action",
      width: final_width,
      renderCell: (params) => (
        <>
          <NormalButton
            title="Editor"
            sx={{ marginRight: "15px" }}
            onClick={() => openEditor(params.row)}
          />
          {params.row.name !== "Home" && (
            <>
              <IconButton onClick={() => handleEdit(params)}>
                <EditIcon sx={{ color: `var(--600)` }} />
              </IconButton>
              <IconButton onClick={() => handleDelete(params)}>
                <DeleteIcon sx={{ color: `var(--601)` }} />
              </IconButton>
            </>
          )}
        </>
      ),
    },
    {
      field: "Url",
      headerName: "Url",
      width: final_width,
      renderCell: (params) => params.row.url,
    },
    {
      field: "Slug",
      headerName: "Slug",
      width: final_width,
      renderCell: (params) => params.row.slug,
    },
    {
      field: "status",
      headerName: "Status",
      width: final_width,
      renderCell: (params) => (
        <IOSSwitch
          checked={
            params.row &&
            (params.row.is_active === "0" || params.row.is_active === 0)
              ? true
              : false
          }
          onChange={() => handleActive(params)}
        />
      ),
    },
  ];
  return (
    <>
      <Helmet>
        <title> Pages </title>
      </Helmet>

      {editor === true ? (
        <WordEditor pageInfo={pageInfo} openEditor={setEditor} />
      ) : (
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Pages
            </Typography>
            {/* <Button className="primar_btn" variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
            <NormalButton
              startIcon={<AddIcon />}
              title="Add Page"
              onClick={() => setModal(true)}
            />
          </Stack>
          <Datatable rows={pageData} columns={columns} loading={isLoading} />
        </Container>
      )}

      <CustomModal
        open={modal}
        setOpen={setModal}
        Component={
          <AddPage open={modal} setOpen={setModal} refetch={refetch} />
        }
      />
      <CustomModal
        open={editModal}
        setOpen={setEditModal}
        Component={
          <EditPage
            open={editModal}
            setOpen={setEditModal}
            refetch={refetch}
            id={id}
            setId={setId}
            URL={"admin/page/update"}
            PageData={PageData}
          />
        }
      />
      <CustomModal
        open={deleteModal}
        setOpen={setDeleteModal}
        Component={
          <DeletePage
            open={deleteModal}
            setOpen={setDeleteModal}
            refetch={refetch}
            id={id}
            setId={setId}
            URL={"admin/page"}
          />
        }
      />
      <CustomModal
        open={activeModal}
        setOpen={setActiveModal}
        Component={
          <ActivePageModal
            open={activeModal}
            setOpen={setActiveModal}
            id={id}
            Status={Status}
            setStatus={setStatus}
            setId={setId}
            URL={"admin/page/isactive"}
            refetch={refetch}
          />
        }
      />
    </>
  );
};

export default Page;

import {
  Button,
  Carousel,
  Heading,
  myCustomBlock,
  animatedDiv,
} from "./customBlocks/CustomBlocks";

import pluginBox from "grapesjs-plugin-toolbox";
import icons from "grapesjs-google-material-icons";
import gjsPresetWebPage from "grapesjs-preset-webpage";
import gjsForms from "grapesjs-plugin-forms";
import blocks from "grapesjs-blocks-basic";
import gjsScroll from "grapesjs-plugin-scroll";
import navbar from "grapesjs-navbar";
import customCodePlugin from "grapesjs-custom-code";
import style from "grapesjs-style-bg";
import imgEditor from "grapesjs-tui-image-editor";
import pluginExport from "grapesjs-plugin-export";
import codeeditor from "grapesjs-component-code-editor";
export const blockManager = {
  blocks: [
    myCustomBlock,
    Heading,
    Button,
    Carousel,
    pluginBox,
    icons,
    animatedDiv,
  ],
};

export const plugins = [
  gjsPresetWebPage,
  blocks,
  navbar,
  gjsForms,
  customCodePlugin,
  pluginBox,
  icons,
  style,
  imgEditor,
  pluginExport,
  codeeditor,
  gjsScroll,
  animatedDiv,
];

export const pluginsOpts = {
  customCodePlugin: {},
  gjsPresetWebPage: {},
  blocks: {
    blocks: [
      myCustomBlock,
      Heading,
      Button,
      Carousel,
      pluginBox,
      icons,
      style,
      imgEditor,
      pluginExport,
      codeeditor,
      animatedDiv,
    ],
  },
  navbar: {},
  gjsForms: {},
  pluginBox: {},
  icons: {},
  style: {},
  imgEditor: {},
  pluginExport: {},
  codeeditor: {},
  gjsScroll: {},
  animatedDiv: {},
};

export const canvas = {
  styles: [
    "https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css",
    "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css",
    "https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css",
  ],
  scripts: [
    "https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js",
    "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.min.js",
    "https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.js",
  ],
};

import { useState } from "react";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
// import { useDispatch } from 'react-redux';
// components

import { useNavigate } from "react-router-dom";
import { TextFields1 } from "../../../components/textFields/TextFields";
import Iconify from "../../../components/iconify";
import { BigButton } from "../../../components/buttons/Buttons";
import { Error, Success, Validate } from "../../../constant/Util";
import { POST } from "../../../constant/RequestAuthService";
// import { userLogin } from '../../../redux_service/authSlice';
import "./register.css";
import { CustomSelect } from "../../../components/textFields/CustomTextField";
import Validator from "validatorjs";

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");
  const [gender, setGender] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleClick = async () => {
    const checkdata = {
      name: "required",
      email: "required|email",
      surname: "required",
      password: "required",
      "confirm password": "required|same:password",
      gender: "required",
      mobile: "required|digits_between:10,13",
      address: "required",
    };
    const sendData = {
      name,
      email,
      surname: surName,
      password,
      "confirm password": confirmpassword,
      gender,
      mobile,
      address,
    };

    const validation = new Validator(sendData, checkdata);

    if (validation.fails()) {
      Validate(validation);
    } else {
      delete sendData["confirm password"];
      const response = await POST("admin/register", sendData);
      console.log(response);
      if (response.status === 200) {
        navigate("/login");
        Success(response.message);
      } else {
        Error(response.message);
      }
    }
  };

  return (
    <>
      <div>
        <Stack spacing={3}>
          <div style={{ gap: "20px" }} className="d-flex align-items-center">
            <TextFields1
              sx={{ flexGrow: 1 }}
              name="name"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextFields1
              sx={{ flexGrow: 1 }}
              name="Surname"
              label="Surname"
              value={surName}
              onChange={(e) => setSurName(e.target.value)}
            />
          </div>
          <div style={{ gap: "20px" }} className="d-flex align-items-center">
            {/* <TextFields1 sx={{ flexGrow: 1 }} name="gender" label="Gender" /> */}
            <CustomSelect sx={{ width: "50%" }}>
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
            </CustomSelect>
            <TextFields1
              sx={{ flexGrow: 1 }}
              type="number"
              name="mobile"
              label="mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>

          <TextFields1
            name="email"
            label="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextFields1
            type="text"
            name="address"
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <div style={{ gap: "20px" }} className="d-flex align-items-center">
            <TextFields1
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextFields1
              value={confirmpassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              name="cpassword"
              label="Confirm Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ my: 2 }}
        >
        </Stack>
        {/* <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton> */}
        <BigButton title={"SignUp"} onClick={handleClick} />
      </div>
    </>
  );
}

import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
//
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import App from "./App";
import "./theme/css/Global.css";

import { store } from "./redux_service/store";
import "./App.scss";
import Scroll from "./scrolltoTop/Scroll";

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <ToastContainer />

      <HashRouter>
        <Scroll />
        <App />
      </HashRouter>
    </HelmetProvider>
  </Provider>
);

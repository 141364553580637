import { createSlice } from "@reduxjs/toolkit";
import StoredVariables, { ClearSession, SetSession } from "../constant/Session";
import { DECODE, ENCODE, Stringify } from "../constant/Util";

const userProfilexist = sessionStorage.getItem(StoredVariables.logindata);
const initialState = {
  userProfile: (userProfilexist && JSON.parse(DECODE(userProfilexist))) || null,
  searchUser: null,
  demoData: null,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLogin: (state, action) => {
      state.userProfile = action.payload;
      SetSession(StoredVariables.logindata, ENCODE(Stringify(action.payload)));
    },
    logoutUser: (state) => {
      state.userProfile = null;
      ClearSession();
    },
    updateUserProfile: (state, action) => {
      state.userProfile = { ...state.userProfile, picture: action.payload };
      SetSession(
        StoredVariables.logindata,
        ENCODE(Stringify(state.userProfile))
      );
    },
    updateUser: (state, action) => {
      const { name, surname, email, address, gender, mobile } = action.payload;
      state.userProfile = {
        ...state.userProfile,
        name,
        surname,
        email,
        address,
        gender,
        mobile,
      };
      SetSession(
        StoredVariables.logindata,
        ENCODE(Stringify(state.userProfile))
      );
    },
    addDemo: (state, action) => {
      state.demoData = action.payload;
    },
  },
});

export const { userLogin, logoutUser, updateUserProfile, updateUser, addDemo } =
  AuthSlice.actions;

export default AuthSlice.reducer;

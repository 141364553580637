import { Buffer } from "buffer";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";

// Base 64 Encode
export const ENCODE = (code) =>
  Buffer.from(
    `MedAppEn-Coding-$2y.S/${Buffer.from(`${code}`).toString(
      "base64"
    )}/$2y.Dev-Mobitplus-MedAppEn-Coding`
  ).toString("base64");

// Base 64 Decode
export const DECODE = (code) =>
  Buffer.from(
    `${Buffer.from(`${code}`, "base64").toString("ascii")}`.split("/")[1],
    "base64"
  ).toString("ascii");

// reload functionallty
export const Reload = () => window.location.reload();

// json to String
export const Stringify = (json) => JSON.stringify(json);

// string to json
export const Json = (string) => JSON.parse(string);

// UserProfile
export function UserProfile() {
  return useSelector((state) => state.auth.userProfile);
}

export const Success = (data) =>
  toast.success(data, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });

export const Error = (data) =>
  toast.error(data, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });

// function for getting error from validatorjs

export const Validate = (data) =>
  Object.values(data.errors.errors).map((x) => Error(`${x}`));

export const Data = () => {
  useSelector((state) => state.auth.demoData);
};

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const linkPropTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export function Link1({ to, title }) {
  return (
    <Link to={to} variant="subtitle2" style={{ color: `var(--700)`, fontWeight: 700 }}>
      {title}
    </Link>
  );
}

export function Link2({ to, title }) {
  return (
    <Link to={to} variant="subtitle2" style={{ color: `var(--801)`, fontWeight: 700 }}>
      {title}
    </Link>
  );
}

Link1.propTypes = linkPropTypes;
Link2.propTypes = linkPropTypes;

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../../images/logo.png";
import { useState } from "react";
// import { Link } from "react-scroll";
import { staticArr } from "../../home/Home";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

function Header({ data }) {
  const [headers, setHeaders] = useState(false);
  const [reachContact, setReachContact] = useState(false);
  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const IsHome = pathname.includes("home");
  const navigate = useNavigate();

  // console.log(pathname.includes("home"));

  function handleScroll() {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop !== 0 && scrollTop + clientHeight >= scrollHeight) {
      setReachContact(true);
    } else {
      setReachContact(false);
    }
  }

  window.addEventListener("scroll", handleScroll);

  //
  const changeNavbar = () => {
    if (window.scrollY >= 1) {
      setHeaders(true);
    } else {
      setHeaders(false);
    }
  };

  window.addEventListener("scroll", changeNavbar);

  const homeClick = () => {
    if (IsHome === true) {
      window.scrollTo(0, 0);
    } else {
      navigate("/home");
    }
  };

  // console.log(` ${staticArr[0][0].toLowerCase()}${staticArr[0].slice(1)}`);

  return (
    <div className="my_nav_fixed container-fluid">
      <Navbar
        // style={{
        //   transition: ".2s linear !important",
        //   transform: "translateY(300)",
        // }}
        fixed="top"
        expand="lg"
        // className={`${headers === false ? "mt-4" : "mt-0"}`}
        className={`${
          headers === false ? "transf_y " : "transf_y_none bg_blue"
        }`}
      >
        <Container>
          <Navbar.Brand className="pointer" onClick={homeClick}>
            <img
              style={{ width: "167px", height: "40px" }}
              src={logo}
              alt="logo"
            />
          </Navbar.Brand>

          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          
          <button
            className={
              menuOpen
                ? "navbar-toggler fa-solid fa-xmark"
                : "navbar-toggler fa-solid fa-bars"
            }
            data-bs-toggle="collapse"
            data-bs-target="#basic-navbar-nav"
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {staticArr?.length &&
                staticArr.map((data, i) =>
                  data.link === true ? (
                    <RouterLink
                      to={`/${data.name[0].toLowerCase()}${data.name.slice(
                        1
                      )} `}
                      key={i}
                    >
                      {data.name}
                    </RouterLink>
                  ) : (
                    <a
                      href={`#${data.name}`}
                      className={`${
                        reachContact === true && i === 5 ? "active" : ""
                      }${IsHome === false && `d-none`}`}
                      key={i}
                      // className="pointer"
                      // to={data.name}
                      // spy={true}
                      // smooth={true}
                      // duration={500}
                      // activeClass=""
                    >
                      {data.name}
                    </a>
                  )
                )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* {staticArr?.length &&
        staticArr.map((data, i) => (
          <div id={data}>
            <h1>hello</h1> <h1>hello</h1> <h1>hello</h1> <h1>hello</h1>{" "}
            <h1>hello</h1>
            <h1>hello</h1> <h1>hello</h1> <h1>hello</h1> <h1>hello</h1>{" "}
            <h1>hello</h1>
            <h1>hello</h1> <h1>hello</h1> <h1>hello</h1> <h1>hello</h1>{" "}
            <h1>hello</h1>
            <h1>hello</h1> <h1>hello</h1> <h1>hello</h1> <h1>hello</h1>{" "}
            <h1>hello</h1>
            <h1>hello</h1> <h1>hello</h1> <h1>hello</h1> <h1>hello</h1>
          </div>
        ))} */}
    </div>
  );
}

export default Header;

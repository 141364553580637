import { Typography } from "@mui/material";
import React, { useState } from "react";
import Validator from "validatorjs";
import { NormalButton } from "../../components/buttons/Buttons";
import { TextFields1 } from "../../components/textFields/TextFields";
import { POST } from "../../constant/RequestAuthService";
import { Success, Error, Validate } from "../../constant/Util";

const EditPage = ({ open, setOpen, refetch, id, setId, URL, PageData }) => {
  // console.log(PageData);
  const [name, setName] = useState(PageData?.name);
  const [url, setUrl] = useState(PageData?.url);
  const [slug, setSlug] = useState(PageData?.slug);

  const setModalOperation = async () => {
    const requestData = {
      name: name.toLowerCase().charAt(0).toUpperCase() + name.slice(1),
      url,
      slug,
    };
    const validation = new Validator(requestData, {
      name: "required",
      url: "required|alpha",
    });
    if (validation.fails()) {
      await Validate(validation);
    } else {
      const response = await POST(URL, { requestData, id: id });
      // console.log(response);
      if (response.status === 200) {
        Success(response.message);
        refetch(id);
        await setId(0);
        await setOpen(!open);
      } else {
        Error(response.message);
        // console.log(response.message);
      }
    }
  };
  return (
    <>
      <Typography variant="h4" gutterBottom>
        Edit Page
      </Typography>
      <TextFields1
        classNames="w-100 mt-2"
        label="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextFields1
        classNames="w-100 mt-2"
        label="url"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <TextFields1
        classNames="w-100 mt-2"
        label="slug"
        value={slug}
        onChange={(e) => setSlug(e.target.value)}
      />
      <div style={{ gap: "15px" }} className="mt-4 d-flex align-items-center">
        <NormalButton title="Submit" onClick={() => setModalOperation()} />
        <NormalButton negative title="Cancel" onClick={() => setOpen(!open)} />
      </div>
    </>
  );
};

export default EditPage;
